import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, vm, trainees, search) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(trainees[0].office_id), style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        const rowItem = [
          { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.fiscal_year_bn : vm.searchHeaderData.fiscal_year_en, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (search.circular_memo_no) ? (i18n.locale === 'bn') ? vm.search.circular_memo_no : vm.search.circular_memo_no : vm.$t('globalTrans.all'), style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem)
        const rowItem1 = [
          { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.training_title_bn : vm.searchHeaderData.training_title_en, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_config.trainee_type'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.other_trainee_type_bn : vm.searchHeaderData.other_trainee_type_en, style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem1)
        const rowItem2 = [
          { text: vm.$t('globalTrans.office'), style: 'td', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? vm.searchHeaderData.office_bn : vm.searchHeaderData.office_en, style: 'td', alignment: 'left' },
          { text: '', style: 'td', alignment: 'left' },
          { text: '', style: 'td', alignment: 'center' },
          { text: '', style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem2)
      if (allRowsHead.length > 0) {
        pdfContent.push({
          table: {
            widths: ['15%', '2%', '30%', '15%', '2%', '30%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      }
      if (trainees.length && vm.materials.length && vm.search.other_trainee_type_id < 2 && vm.search.payment_type === 'Trainee') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : ' & ') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_config.organization') + '/' + vm.$t('elearning_tim.institution'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + ' ' + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + ' ' + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: (info.personal.other_org && i18n.locale === 'bn') ? info.personal.other_org_bn : info.personal.other_org, alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else if (trainees.length && vm.materials.length && vm.search.other_trainee_type_id > 1 && vm.search.payment_type === 'Trainee') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : '&') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.address'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + ' ' + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + ' ' + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: '', alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else if (trainees.length && vm.materials.length && vm.search.payment_type === 'Trainer') {
        const firstHeader = [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.name_of_trainee') + (i18n.locale === 'bn' ? ' ও ' : '&') + vm.$t('admission_form.mobile_no'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_config.organization'), style: 'th', rowSpan: 2, alignment: 'center', bold: true },
          { text: vm.$t('elearning_tim.materials'), style: 'th', colSpan: vm.materials.length, alignment: 'center', bold: true }
        ]
        if (vm.materials.length > 1) {
          for (let i = 1; i < vm.materials.length; i++) {
            firstHeader.push(
              {}
            )
          }
        }
        firstHeader.push({ text: vm.$t('elearning_tim.per_person_daily_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$n(vm.days) + ' ' + (i18n.locale === 'bn' ? ' দিনের ' : ' days ') + ' ' + vm.$t('elearning_tim.total_payment'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.signature_date_&_stamp'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        firstHeader.push({ text: vm.$t('globalTrans.remarks'), style: 'th', rowSpan: 2, alignment: 'center', bold: true })
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {}
        ]
        const widthList = ['7%', '10%', '10%', '10%']
        vm.materials.forEach(dateItem => {
          secondHeader.push(
            { text: i18n.locale === 'bn' ? dateItem.name_bn : dateItem.name, style: 'th', alignment: 'center', bold: true }
          )
          widthList.push('7%')
        })
        secondHeader.push({})
        widthList.push('*', '*', '*', '*')
        allRows.push(secondHeader)
        trainees.filter((info, index) => {
          const rowItem = [
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: i18n.locale === 'bn' ? info.personal.name_bn : info.personal.name, alignment: 'center', style: 'td' },
            { text: (info.personal.designation && i18n.locale === 'bn') ? info.personal.designation_bn : info.personal.designation, alignment: 'center', style: 'td' },
            { text: (info.personal.other_org && i18n.locale === 'bn') ? info.personal.other_org_bn : info.personal.other_org, alignment: 'center', style: 'td' }
          ]
          vm.materials.forEach((material, index1) => {
            rowItem.push(
              { text: vm.$n(material.quantity), alignment: 'center', style: 'td' }
            )
          })
          rowItem.push({ text: vm.$n(info.honour_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({ text: vm.$n(info.payment_amount) + '/-', alignment: 'center', style: 'td' })
          rowItem.push({})
          rowItem.push({})
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: widthList,
            body: allRows
          }
        })
      } else {
        pdfContent.push({ text: vm.$t('elearning_tim.no_material_found'), style: 'hh', alignment: 'center' })
      }
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Protrait',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 10 : 8,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 9,
          bold: true,
          margin: [5, -25, 5, 5]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      para: {
          fontSize: 10,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
